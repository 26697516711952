.blogs_section {
    padding-block: 13rem;

    background-image: url("../../public/images/bg.svg"),
        linear-gradient(to bottom, #111111, var(--background-color));
    background-size: cover;
    background-position: center;
}

.heading {
    padding: 30px;

    border-radius: 20px;
    background-color: rgb(112, 85, 21);

    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)),
        url("../../public/images/project_bg.jpg");
    background-position: center;
    background-size: cover;

    box-shadow: 0 0 5px rgb(109, 109, 109);
}

.blogs_section .container {
    position: relative;
    border-left: 1px solid grey !important;
}

.blogs_content {
    margin-top: 20px;
    display: flex;
    gap: 5.5rem;

    justify-content: space-between;

    background-color: rgba(17, 17, 17, 0.694);

    border-radius: 2.5rem;
    padding: 3rem;

    border: 1px solid rgb(83, 83, 83);
}

.blog_left {
    /* width: 60%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.blog_right img {
    width: 280px;
    height: 200px;
    object-fit: cover !important;
    border-radius: 20px;
    box-shadow: 0 0 8px grey;
}

.blog_title {
    font-size: 2.5rem;
    color: rgb(221, 221, 221);
    font-weight: 500;
}

.blog_date {
    color: rgb(167, 163, 163);
    font-size: 1.6rem;
}

.blog_tags {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    gap: 1.8rem;
    position: relative;
}

.blog_tags span {
    font-size: 1.4rem;
    color: white;
    border: 0.5px solid rgb(31, 31, 31);

    background-color: rgb(8, 8, 8);

    box-shadow: 0 0 5px 1px rgb(88, 88, 88);

    padding: 0.7em 0.9em;
    border-radius: 0.8em;
}
.icon {
    width: 20px !important;
}

.blog_read {
    display: flex;
    align-items: center;
    background-color: rgb(230, 230, 230) !important;

    color: black !important;
    padding: 8px 14px !important;

    font-weight: 500;
    

    gap: 0.8rem;
}

.blogs_section .btn.secondary {
    display: flex;
    gap: 0.8rem;

    align-items: center;
}

.blogs_section .btn.btn.primary {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    border-color: rgb(95, 90, 54) !important;

    color: black;

    margin-top: 20px;
}

.info {
    margin-top: 40px !important;
}

@media screen and (max-width: 600px) {
    .blogs_content:nth-of-type(1) {
        padding-top: 0;
    }

    .blogs_content {
        flex-direction: column;

        padding: unset;
        padding-block: 3rem;

        background-color: transparent;

        gap: 2.2rem;

        border-radius: 0;

        border: none;
        border-bottom: 0.5px solid grey;
    }

    .blogs_content img {
        width: 100%;
    }

    .blog_right {
        order: 1;
    }
    .blog_left {
        order: 2;
    }
}
