@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&family=PT+Serif&family=Young+Serif&display=swap");

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    -webkit-tap-highlight-color: transparent !important;
}

:root {
    --background-color: rgb(10, 10, 10);
    --primary-color: rgb(223, 209, 107);
    --primary-color-light: rgb(129, 121, 59);
    font-size: 62.5%;
}

body {
    background-color: rgb(10, 10, 10);
    font-family: "Montserrat";
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
}

h2 {
    font-size: 6rem;
    text-align: center;
    font-weight: 600;
    color: rgb(223, 223, 223);
}

.info {
    color: rgb(163, 157, 157);
    text-align: center !important;

    font-size: 2.2rem;

    margin: 0 auto;
}

.info.error {
    color: rgb(255, 158, 22);
}

.highlight {
    font-weight: 600;
    color: rgb(229, 188, 39);
}

.btn {
    border: none;
    font-size: 1.8rem;
    padding: 0.7em 0.7em;

    font-weight: 400;
    font-family: "Montserrat";
    color: rgb(211, 211, 211);

    border-radius: 0.25em;

    cursor: pointer;
    transition: 0.15s all ease-in;
}

.btn.primary {
    /* border-radius: 30px; */
    /* background-color: var(--primary-color); */
    /* color: #1d1e1e; */

    background: #c96d30; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #3b4371,
        #c96d30
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #3b4371,
        #c96d30
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn.secondary {
    background-color: transparent;
    color: rgb(218, 214, 214);
    border-radius: 9px;
    border: 1px solid var(--primary-color-light);
}

.container {
    max-width: 1050px;
    margin: 0 auto;
    padding-inline: 20px;
}

/* ================ Nav ============== */

.svg1 {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    width: 100%;
    height: 120%;
    filter: blur(1px);
}

nav {
    z-index: 2;
    position: fixed;
    right: 0;
    width: 100%;
    left: 50%;

    padding-block: 8px;
}

.nav-bg {
    background-color: black;
    border-bottom: 1px solid rgb(30, 30, 30);
}

nav .container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* max-width: unset; */
}

nav .logo {
    font-size: 2.5rem;
    width: 50%;
    font-weight: 600;

    z-index: 10;
}

nav .logo div {
    width: fit-content;
    background: #c96d30;
    background: -webkit-linear-gradient(
        to right,
        #3b4371,
        #c96d30
    );
    background: linear-gradient(
        to right,
        #3b4371,
        #c96d30
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

nav ul {
    display: flex;
    align-items: center;

    text-transform: uppercase;

    width: fit-content;

    font-size: 1.4rem;
    gap: 4rem;

    padding-block: 0.7em;
    padding-inline: 3em;
    color: rgb(156, 153, 153);
}

nav ul:has(.active) {
    font-size: 1.4rem !important;
}

.active {
    background-color: rgb(35, 35, 35);
    color: rgb(215, 212, 212);
    padding: 0.5rem 0.9rem;
    border-radius: 0.5rem;
}

ul li {
    cursor: pointer;
    transition: 0.15s ease-in-out;
}

/* ================= hero ================= */

.hero {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1029%26quot%3b)' fill='none'%3e%3cpath d='M -29.00201772049337%2c78 C 67%2c131.8 259%2c339.6 450.99798227950663%2c347 C 643%2c354.4 739%2c94.8 930.9979822795067%2c115 C 1123%2c135.2 1219%2c438.2 1410.9979822795067%2c448 C 1603%2c457.8 1885.2%2c167.2 1890.9979822795067%2c164 C 1896.8%2c160.8 1530.2%2c378.4 1440%2c432' stroke='rgba(90%2c 147%2c 204%2c 0.29)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -503.7589166250098%2c328 C -407.76%2c309.8 -215.76%2c219.2 -23.758916625009796%2c237 C 168.24%2c254.8 264.24%2c450.6 456.2410833749902%2c417 C 648.24%2c383.4 744.24%2c93.4 936.2410833749902%2c69 C 1128.24%2c44.6 1315.49%2c291.4 1416.2410833749902%2c295 C 1516.99%2c298.6 1435.25%2c128.6 1440%2c87' stroke='rgba(90%2c 147%2c 204%2c 0.29)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -890.4963874563967%2c167 C -794.5%2c215.2 -602.5%2c389.8 -410.49638745639675%2c408 C -218.5%2c426.2 -122.5%2c247.2 69.50361254360325%2c258 C 261.5%2c268.8 357.5%2c492.8 549.5036125436033%2c462 C 741.5%2c431.2 851.4%2c125 1029.5036125436031%2c104 C 1207.6%2c83 1357.9%2c306.4 1440%2c357' stroke='rgba(90%2c 147%2c 204%2c 0.29)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1029'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-repeat: no-repeat;

    background-position: bottom;
}

.hero .container {
    height: 100vh;
    padding: 0;

    display: flex;
    justify-content: center;
}

.hero_content {
    height: max-content;
    margin-top: 150px;

    padding-block: 1em;

    width: 100%;

    font-size: 2.5rem;
    position: relative;
}

.btn.resume {
    border-radius: 30px;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 0.7em 1.1em !important;
}

.btn.resume span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn.resume span > * {
    transition: all 0.3s ease-in-out;
    transform: rotateZ(-30deg);
}

.hero_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.3rem;
}

.greet {
    font-size: 1.2em;
    color: rgb(156, 153, 153);
    font-weight: bold;
}

.name {
    font-size: 5.5em;
    font-weight: bold;

    text-align: center;

    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* padding-inline: 0.2em; */
    border-radius: 10px;

    background: #f7f8f8;
    background: -webkit-linear-gradient(
        to right,
        #acbb78,
        #f7f8f8
    );
    background: linear-gradient(
        to right,
        #acbb78,
        #f7f8f8
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero_bottom {
    margin-top: 10px;
    display: flex;
    gap: 1rem;
}

.hero_top {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    gap: 8px;
}
.hero_img {
    width: 300px;
}

.highlight-bg {
    background-image: linear-gradient(
        to right,
        rgb(26, 93, 93),
        rgb(131, 112, 66)
    );
    white-space: nowrap;
}

.hero_content p {
    font-size: 0.8em;

    border: 1px solid rgb(85, 85, 85);

    background-color: rgba(10, 10, 10, 0.577);

    padding: 3px 15px;

    border-radius: 40px;
    color: rgb(210, 206, 206);
    line-height: 1.5em;
    text-align: center;
}

/* =================== skills =================== */

.skills {
    padding-bottom: 6rem;
}

.skills_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 6rem;
}

.skills_left {
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-top: 1px solid rgb(38, 37, 37);
    background-color: rgba(19, 19, 19, 0.463);

    border-radius: 20px;
    padding: 8rem 10rem;

    width: 45%;

    cursor: pointer;
    transition: 0.2s ease-in;

    position: relative;
}

.section_summary {
    font-size: 1.8rem;
    color: rgb(154, 149, 149);
    line-height: 1.7em;
}

.skills_right {
    width: 55%;
    display: flex;
    flex-direction: column;

    position: relative;
}

.svg2 {
    position: absolute;
    top: 0;
    right: 0;
}

.skills_list {
    width: 100%;
}

.skill_icon,
.icon {
    width: 2.5rem;
    height: 2.5rem;
}

.skill_icon {
    display: flex;
    align-items: center;
    width: 90px;
    height: 90px;

    background-color: #1d1e1e;
    border-radius: 18px;
    padding: 20px;
    margin: 2rem !important;
    box-shadow: 0 0 5px #905c38;
}

/* =============== project ================= */

.projects {
    padding-block: 6rem;
    background: url("../public/images/bg.svg") no-repeat center center fixed;
    background-size: cover;

}

.projects_content {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.projects_content .btn {
    align-self: self-start;
}

.project_list {
    display: flex;
    flex-wrap: wrap;

    gap: 4rem;
}

.project {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.project_img {
    width: 450px;
    height: 280px;
    border: 0.2px solid rgb(44, 44, 44);

    border-radius: 1rem;

    padding: 1rem 1rem;
}

.project_img {
    transition: 0.25s ease-in;
}

.project_title {
    font-size: 2rem;
    font-weight: 500;
    color: wheat;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project_detail {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.project_detail img {
    width: 2.5rem;
}

/* ================= Blogs ================ */

.blogs {
    padding-block: 10rem;
}

.blogs .container {
    position: relative;
    border-left: 1px solid grey;
}

.blogs .container::before {
    content: url("../public/images/test1.svg");
    position: absolute;
    top: -20%;
    left: -5%px;

    width: 20%;
    opacity: 0.7;
}

.blogs_content {
    margin-top: 50px;
    display: flex;
    gap: 5.5rem;
}

.blogs_content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;

    width: 100px;
    height: 100px;
    background-color: #ebb189;

    filter: blur(100px);
}

.blog_left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.blogs_right {
    width: 50%;
}

.blog_right img {
    max-width: 550px;
    box-shadow: 0 0 8px grey;
}

.blog_title {
    font-size: 2.5rem;
    color: rgb(221, 221, 221);
    font-weight: 500;
}

.blog_date {
    color: grey;
    font-size: 1.5rem;
}

.blog_tags {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    gap: 1.5rem;
    padding: 3.8rem 0 0 0;
    position: relative;
}

.blog_read a {
    background-color: rgb(230, 230, 230) !important;

    color: black !important;
    padding: 8px 14px !important;

    font-weight: 500;
}

.blog_tags::before {
    content: "Topic:";
    position: absolute;

    color: white;
    top: 0;
    left: 0;
    font-size: 2rem;
}

.blog_tags span {
    font-size: 1.4rem;
    color: white;
    border: 0.5px solid rgb(31, 31, 31);

    background-color: rgb(8, 8, 8);

    box-shadow: 0 0 5px 1px rgb(88, 88, 88);

    padding: 0.7em 0.9em;
    border-radius: 0.8em;
}

.blogs .btn.secondary {
    display: flex;
    gap: 0.8rem;
}

.blogs .btn.btn.primary {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    border-color: rgb(95, 90, 54) !important;

    margin-top: 20px;
    width: max-content;
}

/* =================== Socials =============== */
section {
    /* border: 1px solid red; */
}

.socials {
    padding: 10rem 3rem;

    border-top: 0.5px solid grey;
    background-image: url("../public/images/bg.svg"),
        linear-gradient(to bottom, #1d1e1e, var(--background-color));
    border-radius: 50px;

    background-size: cover;
    background-position: center;

    position: relative;
}

.socials .container::before {
    content: url("../public/icons/t1.svg");
    position: absolute;
    top: -8%;
    left: 18%;

    transform: rotate(-70deg);

    opacity: 0.4;
    width: 20%;
}

.socials .container .svg_float:nth-of-type(1) {
    bottom: 1%;
    right: 2%;
    opacity: 0.4;
}

.socials .container .svg_float:nth-of-type(2) {
    bottom: -10%;
    left: 2%;
    opacity: 0.4;
}

.svg_float {
    width: 100px;
    height: 100px;
    position: absolute;
}

.socials h2 {
    margin-bottom: 70px;
}

.social_container {
    display: flex;
    align-items: center;

    gap: 6%;
    justify-content: center;

    position: relative;
}

.social_container a {
    /* width: 10%; */
    max-width: 180px;
}

.social_icon {
    display: block;

    width: 8rem;
    padding: 2rem;
    border-radius: 1.8em;
    box-shadow: 0 0 5px rgb(126, 125, 125);
}

.highlight2 {
    font-size: 3.2rem;
    color: rgb(164, 164, 52);
}

@media screen and (max-width: 690px) {
    :root {
        font-size: 50%;
    }
    nav .logo {
        font-size: 2rem;
    }
    nav .logo div {
        width: fit-content;
    }
    nav ul {
        width: fit-content;
        margin-inline: auto;
        padding-inline: 8px;
    }
    nav .container {
        display: flex;
        justify-content: space-between !important;
    }

    .hero_content {
        margin-top: 110px;
        font-size: 2rem;
        padding-inline: 5rem;
    }
    .hero_top {
        flex-direction: column;
    }
    .skills {
        margin-top: -80px;
        padding-bottom: 1rem;
    }
    .skills_content {
        flex-direction: column;
        gap: 0;
    }
    .skills_left,
    .skills_right {
        padding: 6rem 4rem;
        width: 100%;
    }
    .skills_right {
        padding-inline: 3.6rem;
    }

    .projects {
        padding-top: 5rem;
    }

    .blogs {
        padding-top: 120px;
    }
    .blog_right {
        order: 0 !important;
    }
    .blog_left {
        order: 1 !important;
        width: 100%;
    }

    .blogs_content {
        flex-direction: column;
        gap: 2rem;
    }
    .more_blogs {
        margin-top: 25px;
    }

    .social_icon {
        padding: 1rem;
        width: 6rem;
    }
}

@media screen and (max-width: 500px) {
    .btn.resume {
        border-radius: 30px;
    }
    .hero_content p {
        font-size: 0.9em;
        background-color: rgba(10, 10, 10, 0.477);
        color: rgb(210, 206, 206);
        line-height: 1.5em;
        font-weight: 500;
    }
    .project_img {
        width: 320px;
        height: 200px;
    }
    .blogs .container::before {
        top: -25%;
        left: 1%;

        opacity: 0.4;
    }
    .social_container div {
        width: 20%;
    }
    .socials .container::before {
        content: url("../public/icons/t1.svg");
        position: absolute;
        top: 5%;
        left: 2%;

        transform: rotate(-60deg);

        opacity: 0.2;
        width: 16%;
    }
    .svg_float {
        opacity: 0.2 !important;
    }
    .highlight2 {
        font-size: 3rem !important;
    }

    .skill_icon {
        display: flex;
        align-items: center;
        width: 60px !important;
        height: 60px !important;

        background-color: #1d1e1e;
        border-radius: 18px;
        padding: 12px;
        margin: 2rem !important;
        box-shadow: 0 0 5px #7c563d;
    }
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(74, 74, 74);
    border-radius: 5px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@media (hover: hover) {
    ul li:hover {
        color: rgb(215, 212, 212);
    }
    .project_img:hover {
        transform: scale(1.1);
    }
    .skills_left:hover {
        background-color: rgb(24, 24, 24);
    }
    .btn.primary:hover {
        transform: translateY(-2px);
        opacity: 0.8;
    }
    .btn.resume {
        transition: all 0.3s ease-in-out;
    }

    .btn.resume:hover {
        gap: 15px;
    }

    .btn.resume:hover span > * {
        scale: 1.5;
        transform: rotateZ(0deg);
        /* width: 30px; */
    }

    .btn.secondary:hover {
        transform: translateY(-2px);
        opacity: 0.8;
    }
}
