.projects_section {
    padding-block: 15rem;

    background-image: url("../../public/images/bg.svg"),
        linear-gradient(to bottom, #111111, var(--background-color));
    background-size: cover;
    background-position: center;
}

.project_content {
    display: flex;
    flex-direction: column;

    gap: 5rem;
}

.project_controls {
    display: flex;
    flex-direction: column;

    gap: 3rem;

    justify-content: center;
    align-items: center;
    /* width: max-content; */
    margin: 0 auto;

    margin-top: -60px;
}

.project_controls input {
    border: none;
    outline: none;

    width: 60%;

    /* box-shadow: 0 0 8px rgb(113, 108, 54); */

    font-size: 2rem;
    padding: 0.5em 0.6em;
    border-radius: 0.25em;

    color: rgb(157, 156, 156);
    border: 0.5px solid rgb(97, 96, 96);

    /* background-color: #d1d1d1; */
    background-color: rgb(44, 43, 43);
}

input:focus-within {
    box-shadow: 0 0 6px rgb(81, 84, 35);
}

.project_tags {
    display: flex;
    flex-wrap: wrap;

    justify-content: center;

    width: 80%;

    gap: 1.5rem;
}

.project_top h2 {
    padding: 30px;

    border-radius: 20px;
    background-color: rgb(112, 85, 21);

    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)),
        url("../../public/images/project_bg2.jpg");
    background-position: center;
    background-size: cover;

    box-shadow: 0 0 5px rgb(109, 109, 109);
    /* background-blend-mode:lighten */
}

.project_tags span {
    font-size: 2rem;
    color: white;

    box-shadow: 0 0 3px rgb(186, 139, 93);
    /* border: 0.5px solid grey; */
    padding: 0.3em 0.6em;
    background-color: #161312;
    border-radius: 0.3em;

    transition: 0.15s all ease-in;
    cursor: pointer;
}

.project_tags .selected_tag {
    background-color: rgb(109, 121, 13);
    color: white;
}

.project_list {
    display: flex;
    flex-wrap: wrap;

    min-height: 300px;

    gap: 5rem;
}

@media screen and (max-width: 500px) {
    h2 {
        font-size: 4rem;
    }
    .svg {
        width: 40px;
        height: 40px;

        border: 2px solid red;
    }
    .project_list {
        min-height: 150px;
    }
}

@media (hover: hover) {
    .project_tags span:hover {
        background-color: rgb(109, 121, 13);
        color: white;
    }
}
