.project_detail {
    padding-top: 10rem;
    /* border: 1px solid red; */
    height: 100vh;

    background: url(../../public/images/coil.svg);
    background-repeat: no-repeat;

    background-size: cover;
    /* opacity: 0.2; */
}

.highlight {
    font-weight: bold;
    color: rgb(199, 185, 38);
}

.project_content {
    width: 100%;
    height: 100%;

    /* border: 1px solid yellow; */

    display: flex;
    /* gap: 5rem; */

    margin-top: auto;
}
.project_top {
    order: 2;
    width: 50%;
    height: max-content;

    align-self: flex-end;

    box-shadow: 0 0 5px rgb(203, 202, 202);
    /* width: 50%; */
    /* position: relative; */
}

img {
    display: block;
    width: 100%;
}

/* =================== Project detail =================== */

.project_bottom {
    margin-left: auto;
    order: 1;

    padding: 5rem 5rem;

    width: 40%;
    /* border: 1px solid red; */

    display: flex;
    flex-direction: column;
    gap: 2.8rem;
}

.title {
    font-size: 3.3rem;
    color: rgb(228, 222, 222);
}

.description {
    font-size: 2rem;
    color: rgb(190, 185, 185);

    line-height: 3rem;
}

.updated {
    display: flex;
    color: rgb(190, 185, 185);

    font-size: 1.5rem;
}

.date {
    color: rgb(232, 230, 230);
}

.tech_used {
    display: flex;
    flex-direction: column;

    gap: 1.3rem;

    font-size: 2rem;
    color: rgb(190, 185, 185);
}

.tech_list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.3rem;
    max-width: 400px;
}

.tech {
    /* border: 1px solid rgb(179, 196, 52); */
    font-size: 1.6rem;
    color: rgb(234, 231, 231);

    box-shadow: 0 0 3px rgb(163, 170, 34);

    padding: 0.35em 0.6em;
    border-radius: 0.5em;

    background-color: rgb(14, 14, 14);
}

.btn {
    width: max-content;
    font-family: monospace;
    font-size: 2.4rem;
    padding: 0.6em 0.65em;
    border-radius: 0.2em;
    
    background-color: rgb(10, 10, 10);
    border: none;

    color: rgb(211, 208, 208);

    border: 1px solid rgb(96, 97, 96);
    cursor: pointer;

    transition: 0.15s ease-in-out;
}

.btn:hover {
    background-color: rgb(37, 37, 37);
}

@media screen and (max-width: 1240px) {
    .project_bottom {
        width: 60%;
    }
}

@media screen and (750px<= width <=900px) {
    :root {
        font-size: 0.5rem;
    }
    .project_bottom {
        width: 50%;
        
        /* border: 1px solid red; */
    }
}

@media screen and (max-width: 749px) {
    .project_content {
        padding-top: 38rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project_top {
        order: 1;

        width: 90%;
        align-self: unset;
    }
    .project_bottom {
        margin: unset;
        width: 85%;
        padding-inline: 0;
    }
}

@media screen and (max-width: 400px) {
    .project_content {
        padding-top: 5rem;
    }
    .project_bottom {
        padding-block: 3rem;
    }

    .tech {
        font-size: 1.8rem;
    }
}
